import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import imageBuilding from '../../../images/construction/building-development.jpg';
import imageDevPermit from '../../../images/construction/development-permit.jpg';
import imageEngineering from '../../../images/construction/engineering-survey.jpg';
import imageStakeout from '../../../images/construction/house-stakeout-v2.jpg';
import imageLotGrading from '../../../images/construction/lot-grading.jpg';
import imageMainFloor from '../../../images/construction/main-floor.jpg';
import imagePlot from '../../../images/construction/plot-plan.jpg';
import imageRightOfWay from '../../../images/construction/right-of-way.jpg';
import imageStreetscape from '../../../images/construction/screetscape-v2.jpg';
import '../../../styles/media.css';

const ConstructionIndex = () => (
  <Layout
    title="Construction Surveying & Services in Calgary and Red Deer | Trusted by Professionals"
    description="Ensure your construction project runs smoothly with Axiom Geomatics. We offer expert construction surveying services in Calgary for all types of developments."
    keywords="land development, municipal services, building a home, building garage ,building warehouse, landscaping survey, topographical plans, as-built-plans, as-located plans, drainage plans, slope plans, gridlines, excavation, staking, grades, volumes"
  >
    <main>
      <h1>Construction Surveys</h1>
      <p>
        Construction services include all surveys and work required by architects, landscapers,
        developers, engineers and construction crews. Axiom Geomatics offers the following services
        for our construction clients:
      </p>

      <div className="media-list">
        <Link to="/services/construction-services/development-permits">
          <img
            src={imageDevPermit}
            alt="Development Permit survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/development-permits">
            Development Permit Surveys
          </Link>
        </h2>
        <p>
          When building a new house, the initial step for many property owners, developers,
          designers and architects is to request a survey of the property and surrounding area. This
          survey will determine the nature and location of the structures that can be built on the
          property.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/development-permits">
            Learn about Development Permit Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/streetscapes">
          <img
            src={imageStreetscape}
            alt="Streetscape"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/streetscapes">Streetscapes</Link>
        </h2>
        <p>
          A Streetscape is the horizontal view of a Development Permit survey and is usually a
          required component in an application to the municipality for most property development
          work.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/streetscapes">Learn about Streetscapes</Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/plot-plans">
          <img
            src={imagePlot}
            alt="Plot Plan survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/plot-plans">Plot Plans</Link>
        </h2>
        <p>
          The Plot Plan displays the intended shape and dimensions of the new building in relation
          to the property boundaries. It forms the basis of all construction work. The plot plan
          must be approved by the municipal authority before construction commences.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/plot-plans">Learn about Plot Plans</Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/house-stakeouts">
          <img
            src={imageStakeout}
            alt="House stakeout service"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/house-stakeouts">House Stakeouts</Link>
        </h2>
        <p>
          A House Stakeout (or Excavation Survey or Basement Stakeout) is a service that identifies
          the location of building construction as well as the depth to excavate for the basement by
          marking the ground with construction stakes. It ensures you dig in the right place.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/house-stakeouts">
            Learn about House Stakeouts
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/main-floor-and-footing-elevation-surveys">
          <img
            src={imageMainFloor}
            alt="Main Floor survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/main-floor-and-footing-elevation-surveys">
            Main Floor & Footing Elevation Surveys
          </Link>
        </h2>
        <p>
          A Main Floor Elevation Survey or Footing Elevation Survey ensures that the structure being
          built is of the correct height relative to municipal requirements. Depending upon
          municipal requirements you may need either the Main Floor survey or the Footing Elevation
          survey.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/main-floor-and-footing-elevation-surveys">
            Learn about Main Floor & Footing Elevation Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/lot-grading-certificates">
          <img
            src={imageLotGrading}
            alt="Lot Grading Certificate sample"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/lot-grading-certificates">
            Lot Grading Surveys & Certificates
          </Link>
        </h2>
        <p>
          A Lot Grading Survey is a type of municipal survey designed to determine which direction
          water will drain from the property and that landscaping meets the Plot Plan
          specifications. A Lot Grading Certificate may be required by the municipality indicating
          water drainage is appropriate.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/lot-grading-certificates">
            Learn about Lot Grading Surveys & Certificates
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/utility-construction-surveys">
          <img
            src={imageRightOfWay}
            alt="Right-of-Way survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/utility-construction-surveys">
            Utility Construction Surveys
          </Link>
        </h2>
        <p>
          A Utility Construction Survey determines the future location where utilities will be
          located on a property. These utilities include fiber-optic lines, power, natural gas, as
          well as water and sewage lines.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/utility-construction-surveys">
            Learn about Utility Construction Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/large-building-construction">
          <img
            src={imageBuilding}
            alt="Large-scale building construction"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/large-building-construction">
            Large Building Construction
          </Link>
        </h2>
        <p>
          Large Building Construction involves multiple surveys to ensure that the commercial
          building, high-rise condominium or parking lot structure projects are built as efficiently
          as possible.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/large-building-construction">
            Learn about Large Building Construction
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/construction-services/engineering-surveys">
          <img
            src={imageEngineering}
            alt="Engineering survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/construction-services/engineering-surveys">Engineering Surveys</Link>
        </h2>
        <p>
          Axiom Geomatics leverages their survey expertise to support other engineering disciplines.
          This work includes volume calculations, slope stability surveys and more.
        </p>

        <p className="align-right">
          <Link to="/services/construction-services/engineering-surveys">
            Learn about Engineering Surveys
          </Link>
        </p>
      </div>
    </main>
  </Layout>
);

export default ConstructionIndex;
